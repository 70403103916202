import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Alert, Button, Snackbar, Stack, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, colors } from "@mui/material";
import { ReportProblemOutlined } from "@mui/icons-material"; 
import "./App.css";
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2'; 

import { useUuid } from './useUuid';

const backendserver = process.env.NODE_ENV === "production" ? "91.150.107.115:12123" : "192.168.10.16:12123"

 let loops1=[]
 
function FileUpload() {
	const [selectedFile, setSelectedFile] = useState(null);
	const [showMessage, setShowMessage] = useState(false);
	const [messageText, setMessageText] = useState("Poruka greske default.");
	const [messageType, setMessageType] = useState("error");
	const [uploadProgress, setUploadProgress] = useState(0);
	const [videoUrl, setVideoUrl] = useState("");
	const [isDrawingStarted, setIsDrawingStarted] = useState(false);
	const [points, setPoints] = useState([]);
	const [shapes, setShapes] = useState([]);
	const uploadRef = useRef(null);
	const canvasRef = useRef(null);
	const {uuid, resetUuid} = useUuid();
	const [location, setLocation]=useState(null);
	const [coordinatesList, setCoordinatesList] = useState([]);
	const [status, setStatus] = useState({ queue: 0, progress: 0 });
	const [report, setReport] = useState([]);
	const [reportData, setReportData] = useState(null);
	const [loopIds, setLoopIds] = useState([]);
	const [loopIdToDelete, setLoopIdToDelete] = useState("");  
	const [shapeToDelete, setShapeToDelete] = useState(null);
	const [showUploadConfirmation, setShowUploadConfirmation] = useState(false);
	const [isDrawingAllowed, setIsDrawingAllowed] = useState(true);
	const [showProgressBar, setShowProgressBar] = useState(false);
	const [drawnShapes, setDrawnShapes] = useState([]); 
	const [showReportDialog, setShowReportDialog] = useState(false);
	const [canvasHeight, setCanvasHeight]=useState(500);
	const [canvasWidth, setCanvasWidth]=useState(500);
	const [isVideoSelected, setIsVideoSelected] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [selectedLoopId, setSelectedLoopId] = useState(null);
	const [selectedShape, setSelectedShape] = useState(null);
	const videRef = useRef(0);
	const [origvideoHeight, setOrigVideoHeight]=useState(null)
	const [origvideoWidth, setOrigVideoWidth]=useState(null)
	const [playbackRate, setPlaybackRate] = useState(1);
	const [isFirstLoopDrawn, setIsFirstLoopDrawn] = useState(false);
	const [stream, setStream]=useState(false);
	const [showLiveStream, setShowLiveStream] = useState(false);
	const [isVideoUploaded, setIsVideoUploaded] = useState(false);
	const [hasWaited, setHasWaited] = useState(false);
	const [showTwoButtonDialog, setShowTwoButtonDialog] = useState(false);
	const [isVideoUploadedWithNewUuid, setIsVideoUploadedWithNewUuid] = useState(false);
	const order = ["person", "bicycle", "motorbike", "car", "van", "bus", "tram", "ltv", "ttv", "av"];
	const [hasRows, setHasRows] = useState(false);
	const [tableHasRows, setTableHasRows] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);


	let I_getStatus = null;
	let I_checkStream = null;

	const handleFileChange = (event) => {

		setSelectedFile(event.target.files[0]);
		const video = document.getElementById('videov');


		setVideoUrl(URL.createObjectURL(event.target.files[0]));
		setTimeout(() => {
			console.log("TO",videRef);
			setCanvasHeight(videRef.current.offsetHeight);
			setCanvasWidth(videRef.current.offsetWidth);
		}, 1000);
		setIsVideoSelected(true);
	};

	const handleDownload = () => {
		if (reportData) {
			const reportContent = generateReportContent(reportData, order); // Dodajte 'order' kao argument
			const blob = new Blob([reportContent], { type: 'text/csv' });
			saveAs(blob, 'report.csv');
		}
	};

	const generateReportContent = (reportData) => {
		let csvContent = "ID," + order.join(",") + "\n"; // Zaglavlje

		Object.entries(reportData).forEach(([id, data]) => {
			const rowData = `${id},${order.map(className => data[className]).join(",")}\n`;
			csvContent += rowData;
		});

		return csvContent;
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};


	const playVideo = () => {
		const video = document.querySelector('.Video');
		if(video) {

			video.play();
		}
	};

	const pauseVideo = () => {
		const video = document.querySelector('.Video');
		if (video) {
			video.pause();
		}
	};

	const stopVideo = () => {
		const video = document.querySelector('.Video');
		if (video) {
			video.currentTime = 0;
			video.pause();
		}
	};

	const speedUpVideo = () => {
		const video = document.querySelector('.Video');
		if (video) {
			const newPlaybackRate = playbackRate + 0.50; // Ubrzanje za 0.25
			video.playbackRate = newPlaybackRate;
			setPlaybackRate(newPlaybackRate);
		}
	};

	const slowVideo=()=>{
		const video = document.querySelector('.Video');
		if (video) {
			const newPlaybackRate = playbackRate - 0.50; // Ubrzanje za 0.25
			video.playbackRate = newPlaybackRate;
			setPlaybackRate(newPlaybackRate);
		}
	}

	const resetSpeed = () => {
		const video = document.querySelector('.Video');
		if (video) {
			video.playbackRate = 1;
			setPlaybackRate(1);
		}
	};

	const showAlert = (type, text) => {
		setMessageType(type);
		setMessageText(text);
		setShowMessage(true);
	};

	const handleUpload = () => {
		if (!selectedFile) {return}
		const formData = new FormData();
		formData.append('file', selectedFile);

		axios
			.post(`http://${backendserver}/upload/${uuid}`, formData, {
				onUploadProgress: (progressEvent) => {
					const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setUploadProgress(progress);
				},
			})
			.then((response) => {

				console.log('Fajl je uspešno uploadovan na server.', response);
				showAlert('success', 'Fajl je uspešno uploadovan na server.');      
				setIsVideoUploaded(true);  

			})
			.catch((error) => {        
				if (error.response && error.response.status === 400) {
					console.error('Greška prilikom slanja fajla na server.', error);
					Swal.fire({icon:'error', text:'Upload nije uspeo.',});
				}
				else if(error.response && error.response.status === 406){
					console.error('Problem sa konekcijom. Fajl ne postoji', error);
					showAlert('error', 'Greška prilikom konekcije.');
				}
				else if(error.response && error.response.status === 500)
				{
					console.log("Greška na serveru.");
					showAlert('error', 'Greška na serveru!');
				}
				setUploadProgress(0);
			});

	};


	useEffect(handleUpload, [hasWaited]);

	// useEffect(() => {
	//   sendCoordinates(); // Poziv sendCoordinates nakon postavljanja UUID-a
	// }, [uuid, shapes]);


	const handleCanvasClick = useCallback((event) => {
		if (isDrawingStarted) {
			const canvas = canvasRef.current;
			const rctx = canvas.getContext('2d');
			const rect = canvas.getBoundingClientRect();
			const x = event.clientX - rect.left;
			const y = event.clientY - rect.top;

			rctx.fillStyle = 'green';
			rctx.beginPath();
			rctx.arc(x, y, 5, 0, 2 * Math.PI);
			rctx.stroke();
			rctx.fill();

			if (points.length >= 2) {
				drawClosedShape();
				const loopId = parseInt(window.prompt('Unesite ID petlje:', loops1.length + 1));
				if (!isNaN(loopId) && !loopIds.includes(loopId)) {
					setLoopIds((prevLoopIds) => [...prevLoopIds, loopId]);
					setTableData((prevTableData) => [...prevTableData, { id: loopId }]);
					setSelectedLoopId(loopId); // Postavite selektovani ID loop-a
					setSelectedShape(drawnShapes.length - 1); // Postavite indeks poslednjeg dodatog oblika
				}
			} else {
				setPoints((prevPoints) => [...prevPoints, { x, y }]);
			}
		}
	}, [isDrawingStarted, points, selectedLoopId, loopIds, drawnShapes]);

	const uniqueLoopIds = useMemo(() => {
		return loopIds.filter((value, index, self) => self.indexOf(value) === index);
	}, [loopIds]);


	useEffect(() => {
		// Pronađi red u tabeli sa odgovarajućim ID-jem loop-a i selektuj ga
		const tableRows = document.querySelectorAll('.LoopTable tbody tr');
		tableRows.forEach((row) => {
			if (row.dataset.loopid === String(selectedLoopId)) {
				row.classList.add('selected');
			} else {
				row.classList.remove('selected');
			}
		});
	}, [selectedLoopId]);


	const drawClosedShape = useCallback(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		if (points.length >= 2) {
			const loopId = parseInt(window.prompt('Unesite ID petlje:', loops1.length + 1));
			const loopIdAsInt = parseInt(loopId, 10);
			if (isNaN(loopIdAsInt) || loopIdAsInt !== parseFloat(loopId) || loopIdAsInt === 0) {
				window.alert('Unesite validan ID petlje, mora biti ceo broj.');
				drawClosedShape();
				return;
			}

			setTableData((prevTableData) => [...prevTableData, { id: loopId }]);

			// Proverite da li ID već postoji u listi loopIds
			if (loopIds.includes(loopId)) {
				window.alert('Loop sa unetim ID-jem već postoji. Molimo unesite drugi ID.');
				drawClosedShape();
				// Rekurzivno pozivamo funkciju ponovo
				return;
			}

			ctx.strokeStyle = 'blue';
			ctx.beginPath();
			ctx.moveTo(points[0].x, points[0].y);

			for (let i = 0; i < points.length; i++) {
				ctx.lineTo(points[i].x, points[i].y);
			}

			ctx.closePath();
			ctx.stroke();

			setDrawnShapes((prevShapes) => [...prevShapes, [...points]]);
			setPoints([]);
			setShapes((prevShapes) => [...prevShapes, [...points]]);

			loops1.push([loopId, ...points.map((point) => [point.x, point.y]).flat()]);

			setLoopIds((prevLoopIds) => [...prevLoopIds, loopId]);
			setSelectedLoopId(loopId);
			setLoopIdToDelete(loopId.toString());
			setHasRows(true); 
			setTableHasRows(true);


			const latestCoordinateItem = coordinatesList[coordinatesList.length - 1];
			if (latestCoordinateItem) {
				// console.log("UUID:", latestCoordinateItem.uuid);
				// console.log("Location:", latestCoordinateItem.location);
			}
			console.log("Loops:", loops1);
		}

		setIsFirstLoopDrawn(true);
	}, [points, loops1, loopIds, coordinatesList]);


	const showConfirmationDialog = () => {
		setShowUploadConfirmation(true);
	};

	const hideConfirmationDialog = () => {
		setShowUploadConfirmation(false);
	};

	const continueUpload = () => {
		hideConfirmationDialog();
		sendCoordinates();

	};

	const handleDeleteLoop = () => {
		const loopIdToDeleteInt = parseInt(loopIdToDelete);

		if (isNaN(loopIdToDeleteInt)) {
			showAlert("error", "Morate uneti validan broj za ID petlje.");
			return;
		}

		const loopIndexToDelete = loopIds.findIndex((id) => id === loopIdToDeleteInt);

		if (loopIndexToDelete === -1) {
			showAlert("error", "Niste selektovali red u tabeli.");
			return;
		}
		setDrawnShapes((prevShapes) => {
			const newShapes = [...prevShapes];
			newShapes.splice(loopIndexToDelete, 1);
			return newShapes;
		});
		// Setujte ID oblika koji želite da obrišete
		setShapeToDelete(loopIndexToDelete);

		setShapes((prevShapes) => {
			const newShapes = [...prevShapes];
			newShapes.splice(loopIndexToDelete, 1);
			return newShapes;
		});

		setLoopIds((prevLoopIds) => {
			const newLoopIds = [...prevLoopIds];
			newLoopIds.splice(loopIndexToDelete, 1);
			return newLoopIds;
		});
		setSelectedShape(null);
		setSelectedLoopId(null);
		showAlert("success", "Loop je uspešno izbrisan.");

		if (loopIds.length === 0) {
			setTableHasRows(false); // Postavite na false kad su svi redovi izbrisani
		}
	};

	useEffect(() => {
		if (selectedLoopId !== null && !loopIds.includes(selectedLoopId)) {
			setSelectedLoopId(null); // Ažurirajte selectedLoopId ako trenutno selektovani loop više ne postoji u nizu loopIds
		}
	}, [loopIds, selectedLoopId]);



	useEffect(() => {
		if (shapeToDelete !== null && canvasRef.current) {
			const canvas = canvasRef.current;
			const ctx = canvas.getContext('2d');
			ctx.clearRect(0, 0, canvas.width, canvas.height);


			// Nacrtajte preostale oblike ponovo
			drawnShapes.forEach((shape) => {
				ctx.strokeStyle = 'blue';
				ctx.beginPath();
				ctx.moveTo(shape[0].x, shape[0].y);
				for (let i = 0; i < shape.length; i++) {
					ctx.lineTo(shape[i].x, shape[i].y);
				}
				ctx.closePath();
				ctx.stroke();
			});

			// Resetujte promenljivu za brisanje oblika na null
			setShapeToDelete(null);
		}
	}, [shapeToDelete, shapes]);

	useEffect(() => {
		if (points.length >= 2) {
			drawClosedShape();
		}
	}, [points]);

	useEffect(() => {
		const canvas = canvasRef.current;
		const video = document.querySelector('.Video');

		if (canvas && video && isDrawingStarted) {
			// canvas.width = video.videoWidth;
			// canvas.height = video.videoHeight;
			if (isDrawingAllowed) {
				canvas.addEventListener('click', handleCanvasClick);
			} else {
				canvas.removeEventListener('click', handleCanvasClick);
			}
			// Crtanje postojećih oblika
			const ctx = canvas.getContext('2d');
			drawnShapes.forEach((shape, index) => {
				const loopId = loopIds[index]; // Dobavite ID za odgovarajući oblik
				ctx.strokeStyle = loopId === selectedLoopId ? 'yellow' : 'blue'; // Crvena boja za selektovani oblik
				ctx.beginPath();
				ctx.moveTo(shape[0].x, shape[0].y);
				for (let i = 0; i < shape.length; i++) {
					ctx.lineTo(shape[i].x, shape[i].y);
				}
				ctx.closePath();
				ctx.stroke();
			});
		}

		return () => {
			if (canvas) {
				canvas.removeEventListener('click', handleCanvasClick);
			}
		};
	}, [videoUrl, isDrawingStarted, handleCanvasClick, isDrawingAllowed, drawnShapes]);

	const sendCoordinates = () => {

		// setUuid("HELLO-UUID");
		// Formirajte niz oblika sa ID i koordinatama tačaka
		const shapeLoops = shapes.map((shape, index) => [
			index + 1,
			...shape.reduce((acc, point) => [...acc, point.x, point.y], []),
		]);

		// Kreirajte objekat sa UUID, location i oblikom loops
		const coordinatesData = {
			uuid,        
			stream: showLiveStream,
			loops: shapeLoops.map((shape, index) => [
				loopIds[index], // Koristi uneti ID, koji će biti undefined ako nije unet
				...shape.slice(1), // Preskoči prvi element (automatski generisani ID) iz niza tačaka
			]),
		};

		const jsonString = JSON.stringify(coordinatesData);      
		setCoordinatesList([jsonString]);

		var transcords=JSON.parse(jsonString);
		console.log("asda",transcords);
		for(let l = 0; l< transcords.loops.length; l++)
		{
			for(let p= 0; p < transcords.loops[l].length; p++){
				if(p>0){
					if((p-1)%2 == 0){
						transcords.loops[l][p] = parseInt(transcords.loops[l][p]*origvideoWidth/canvasWidth);
					}else{
						transcords.loops[l][p] = parseInt(transcords.loops[l][p]*origvideoHeight/canvasHeight);
					}
					console.log("PROMENA " + transcords.loops[l][p]);
				}
			}
		}   
		console.log("transcoords",transcords);

		axios
			.post(`http://${backendserver}/reserve-stream`, JSON.stringify(transcords), 
				{  headers: {
					'Content-Type': 'application/json'
				}
				})
			.then((response) => {
				console.log('Koordinate su uspešno poslane na server.', response);
				console.log("START INTERVALA")  
				if (response.data === "moze")
					setHasWaited(true);
				else if(response.data==='obradjeno'){
					setShowTwoButtonDialog(true);
				}

				fetchStatus();  

			})
			.catch((error) => {
				console.error('Greška prilikom slanja koordinata na server.', error); 
				showAlert('error', "Kordinate nisu poslate na server. Nema nacrtanih petlji.");
			});      
	};

	const toggleDrawing = () => {
		if (isDrawingAllowed) {
			setIsDrawingStarted(!isDrawingStarted);
		}
	};

	useEffect(() => {
		if (coordinatesList.length > 0) {
			console.log(JSON.stringify(coordinatesList));
		}
	}, [coordinatesList]);


	useEffect(() => {
		const video = videRef.current;  
		const handleVideoLoad = () => {

			setOrigVideoWidth(video.videoWidth);
			setOrigVideoHeight(video.videoHeight);
			console.log("Visina videa:", video.videoHeight);
			console.log("Širina videa:", video.videoWidth);
		};
		if (videoUrl) {
			video.addEventListener('loadedmetadata', handleVideoLoad);
		}
		return () => {
			if (videoUrl) {
				video.removeEventListener('loadedmetadata', handleVideoLoad);
			}
		};
	}, [videoUrl]);  

	let waitingCount = 0;
	let totalWaitingCount = 0;

	const fetchStatus = () => {

		const eventSource = new EventSource(`http://${backendserver}/status/${uuid}`);

		eventSource.onopen = () => {
			console.log('Konekcija je uspostavljena.');
		};

		eventSource.addEventListener("reservation", (data) =>{
			console.log("RESERVATION"); 
			const queuePosition = parseInt(data.data);
			showAlert('info', `Uspeli da rezervišete. Trenutno ste ${queuePosition}. u redu.`);
			setStatus({ queue: queuePosition, progress: queuePosition });
		})

		eventSource.addEventListener("waiting", (data)=>{
			console.log("WAITING",data);

			const [currentWaiting, totalWaiting] = data.data.split('/').map(Number);
			if (!hasWaited) {
				setHasWaited(true)
				showAlert('info', "Čekanje je u toku...");
			}
			setShowProgressBar(true);
			setStatus({ queue: currentWaiting, progress: totalWaiting });
			waitingCount = currentWaiting;
		})

		eventSource.addEventListener("processing", (event)=>{
			console.log("PROCESSING", event);
			if(stream){
				LiveStream();
			}
			setShowProgressBar(true);
			setStatus({ queue:status.queue,  progress: parseInt(event.data)});
			//LiveStream();  
		})

		eventSource.addEventListener("report", (data)=>{
			console.log("REPORT", data);
			const order = ["person", "bicycle", "motorbike", "car", "van", "bus", "tram", "ltv", "ttv", "av"];
			const sortedReportData = {};
			Object.keys(data.data)
				.sort((a, b) => order.indexOf(a) - order.indexOf(b))
				.forEach((key) => {
					sortedReportData[key] = data.data[key];
				});

			setReportData(JSON.parse(data.data));
			console.log("Tabela: ", data.data);
			eventSource.close();
			Swal.fire({icon:'success', text:'Uspesno. Dobili ste izveštaj!'})

		}) 

		eventSource.onerror = (error) => {
			console.error('Greška prilikom dobijanja statusa sa servera.', error);
			Swal.fire({icon:'error', text:'Greška na serveru.'})
			setStatus({ queue: 0, progress: 0 });
			eventSource.close();
		};
	};



	const handleUploadWithNewUuid = () => {
		// Resetujte UUID
		resetUuid();

		setHasWaited(false);
		sendCoordinates();
	};



	const LiveStream = () => {
		const video = videRef.current;
		video.src = `http://${backendserver}/live-stream/${uuid}`; // Postavite putanju do live streama
		video.autoplay = true;
		video.width = 1280;
		video.height = 720;

		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');
	};  

	const showReportDialogHandler = () => {
		if (reportData) {
			setShowReportDialog(true);
		}
	};  



	function ReportTable({ reportData }) {
		const order = ["person", "bicycle", "motorbike", "car", "van", "bus", "tram", "ltv", "ttv", "av"];
		return (
			<div>
			<table>
			<thead>
			<tr>
			<th>ID</th>
			{order.map((className) => (
				<th key={className}>{className}</th>
			))}
			</tr>
			</thead>
			<tbody>
			{Object.entries(reportData).map(([id, data]) => (
				<tr key={id}>
				<td>{id}</td>
				{order.map((className) => (
					<td key={className}>{data[className]}</td>
				))}
				</tr>
			))}
			</tbody>
			</table>
			</div>
		);
	}

	function LoopTable({ data }) {
		return (
			<>
			{ isFirstLoopDrawn && (          
				<table className="LoopTable">
				<thead>
				<tr>
				<th>ID petlje</th>
				</tr>
				</thead>
				<tbody>
				{data.map((item) => (
					<tr
					key={item.id}
					data-loopid={item.id}
					onClick={() => {
						setSelectedLoopId(item.id);
						setLoopIdToDelete(item.id.toString()); // Postavi vrednost za unos ID-a
					}}
					className={selectedLoopId === item.id ? 'selected' : ''}
					>
					<td>{item.id}</td>
					</tr>
				))}
				</tbody>
				</table>
			)}
			</>
		);
	} 



	return (    
		<div className="Message">

		<div className="topbar">
	
		<div className='logoContainer'>
		<img src="logo.png" alt="Logo" style={{width:'25%', height:'auto'}} className="logo" />
		</div>
		{/* <text className='zdravo'> Zdravo, User</text>
	  <button className='btnOdjava'>Odjava</button>           */}
		</div>


		{!isVideoSelected && (
			<input className='btnSelectfile' type="file" onChange={handleFileChange}/>
		)}

		<div>
		<button className="btnTutorial" role="button" onClick={openModal}>
		<span className="text">Tutorial</span>
		</button>

		{isModalOpen && (
			<div className="modal">
			<div className="modal-content">
			<button className="btnClose" onClick={closeModal}>X</button>
			<iframe src="/TUTORIAL.pdf" title="TUTORIAL PDF" />
			</div>
			</div>
		)}
		</div>


		{!isVideoSelected ? null :(

			<div className='Hidden'>
			<Snackbar
			open={showMessage}
			autoHideDuration={5000}
			onClose={() => setShowMessage(false)}
			anchorOrigin={{
				vertical: 'top',
					horizontal: 'center',
			}}
			>
			<Alert severity={messageType}>
			<Stack direction="row" alignItems="center" spacing={2}>
			<div>{messageText}</div>
			<Button variant="contained" onClick={() => setShowMessage(false)}>
			OK
			</Button>
			</Stack>
			</Alert>
			</Snackbar>

			<div className="ProgressBarContainer">
			{uploadProgress > 0 || showProgressBar ?  (
				<LinearProgress variant="determinate" value={status.progress || uploadProgress || status.queue } style={{ width: '30%', margin: '20px auto', height:'2%', position:'absolute', top:'95%', left:'34%', background:'white'  }} />
			) : <div></div>}
			</div>

			<div className="VideoWrapper">
			{showLiveStream && isVideoUploaded ? (
				<div className="VideoContainer">
				<img src={`http://${backendserver}/live-stream/${uuid}`} alt="Live Stream" width={canvasWidth} height={canvasHeight} />
				{isVideoUploadedWithNewUuid && (
					<button className="btnShowReport" onClick={showReportDialogHandler}>
					Prikazi izvetaj
					</button>
				)}


				</div>
			) : (
				videoUrl && (
					<div className="VideoContainer">
					<video ref={videRef} id="videov" className="Video" autoPlay="autoplay">

					<source src={videoUrl} type="video/mp4" />
					<source src={videoUrl} type="video/ogg" />
					</video>
					<canvas 
					ref={canvasRef}
					id="theCanvas"
					className="Canvas"
					disabled={!isDrawingStarted}
					width={canvasWidth}
					height={canvasHeight}
					/>


					</div>    
				)
			)}

			<div className='playerbar' width={canvasWidth}>

			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"/>
			<button className="btnStopVideo" title='Stopiraj video' onClick={stopVideo}>
			<i class="fas fa-stop"></i>
			</button>

			<button className="btnPlayVideo" title='Pusti video' onClick={playVideo}>
			<i class="fas fa-play"> </i> 
			</button>

			<button className="btnPauseVideo" title='Pauziraj video' onClick={pauseVideo}>
			<i class="fas fa-pause"></i>
			</button>


			<button className="btnSpeedUpVideo" title='Ubrzaj video za 0.5x' onClick={speedUpVideo}>
			<i class="fa fa-fast-forward"></i> 0.5
			</button>

			{/* <button className="btnSlowVideo" title='Uspori video za 0.5x' onClick={slowVideo}>
<i class="fa fa-fast-backward"></i> 0.5
</button> */}

			<button className="btnResetSpeed" title='Resetuj brzinu videa na normalnu (1x)'  onClick={resetSpeed}>
			Reset Speed
			</button>

			<button
			className={`btnLiveStream ${showLiveStream ? "":"redText"}`} 
			disabled={isVideoUploaded || showLiveStream}
			onClick={(e) => {
				e.preventDefault();
				setShowLiveStream(!showLiveStream);
			}}
			title={showLiveStream? "Ne zelim da pustim live stream":"Zelim da pustim live stream"}

			>
			{showLiveStream ? "Live stream" : "Live stream"}
			</button>

			</div>
			</div>

			<input
			className='placeholderID'
			type="text"
			value={loopIdToDelete}
			onChange={(e) => setLoopIdToDelete(e.target.value)}
			placeholder="Unesite ID petlje za brisanje"
			/>

			{tableHasRows && (
				<>
				<LoopTable data={uniqueLoopIds.map((id) => ({ id }))} />
				<button className='btnBrisiID' title='Brisi selektovani red' onClick={handleDeleteLoop}>
				🗑️
				</button>
				</>
			)}

			<Dialog
			open={showUploadConfirmation}
			onClose={hideConfirmationDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">
			<Stack direction="row" alignItems="center" spacing={1}>
			<ReportProblemOutlined color="warning" fontSize="large" /> {/* Disclaimer icon */}
			{"Upload video"}
			</Stack>
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
			Da li želite da prihavtite uslove korišćenja?
			</DialogContentText>
			</DialogContent>
			<DialogActions>          
			<button className='buttonYes' onClick={continueUpload}  autoFocus>
			Da, slazem se.
			</button>
			<button className='buttonNo' onClick={hideConfirmationDialog} >
			Ne prihvatam!
			</button>
			</DialogActions>
			</Dialog>

			{showTwoButtonDialog && (
				<Dialog
				open={true}
				onClose={() => setShowTwoButtonDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				>
				<DialogTitle id="alert-dialog-title">Two Button Dialog</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
				Choose an action:
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button
				onClick={() => {
					setShowTwoButtonDialog(false);
					showReportDialogHandler(); // Otvori dijalog za prikaz reporta
				}}
				>
				Show Report
				</Button>
				<Button
				onClick={() => {
					setShowTwoButtonDialog(false);
					handleUploadWithNewUuid(); // Izvrši upload sa resetovanim UUID-om
				}}
				>
				Upload with New UUID
				</Button>
				</DialogActions>
				</Dialog>
			)}


			{reportData && (
				<button className="btnShowReport" onClick={showReportDialogHandler}>
				Prikaži izveštaj
				</button>
			)}


			<button className="btnUpload" disabled={!isDrawingAllowed && stream}onClick={showConfirmationDialog} disabled={isVideoUploaded} >
			Upload video
			</button>

			<button className="btnZapocni" onClick={toggleDrawing} disabled={!isVideoSelected}>
			{isDrawingStarted ? "Zaustavi" : "Započni"} crtanje
			</button>
			{showReportDialog && reportData && (
				<Dialog open={true} onClose={() => setShowReportDialog(false)}>
				<DialogTitle>Izvestaj</DialogTitle>
				<DialogContent>
				<ReportTable reportData={reportData} />
				</DialogContent>
				<DialogActions>
				<Button onClick={handleDownload}>Download</Button>
				<Button onClick={() => setShowReportDialog(false)}>Close</Button>
				</DialogActions>
				</Dialog>
			)}    
			</div>
		)}
		</div>    
	);
}

export default FileUpload;
